#general-question-container .PrivateSwitchBase-input {
    display: none !important;
}

#general-question-container .MuiSvgIcon-root {
    display: none !important;
}

#general-question-container .MuiButtonBase-root {
    display: none !important;
}

.description-questions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-questions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width: 768px) {
    #general-question-container .description-questions {
        padding-top: 10px;
        font-size: 14px !important;
        font-weight: initial;
    }
    #general-question-container .title-questions {
        padding-bottom: 10px;
        font-size: 18px !important;
    }
    #reusable-questionare-container {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }

    #reusable-questionare-container .MuiSwitch-thumb {
        background-color: #fff !important;
        width: 22px !important;
        height: 22px !important;
    }

    #reusable-questionare-container .MuiSwitch-root {
        width: 42px !important;
        height: 26px !important;
    }

    #reusable-questionare-container .MuiSwitch-switchBase.Mui-checked {
        transform: translateX(16px) !important;
    }
}
@media (max-width: 860px) {
    #reusable-questionare-container {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
}


@media (min-width: 1024px) {
    #general-question-container {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
}