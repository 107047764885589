.steps-header{
    font-size: 57px;
    text-align: center;
    padding-top: 11px;
    padding-left: 18px;
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: -4px;
    color: #3e4959;
    font-weight: 800;
}

.MuiCalendarPicker-root{
    background-color: white;
}

.css-1ltthlx-MuiTypography-root{
    font-size: 20px !important;
}

.steps-subheader{
    font-size: 25px !important;
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: -2px;
    color: black !important;
}

.input-label{
    font-size: 19px !important;
    font-weight: 800;
    padding-bottom: 17px;
    padding-left: 3px;
    color: black !important;
}

.MuiStepIcon-root{
    display: none !important;
}

.MuiStepConnector-horizontal{
    display: none !important;
}

.steper-header-item-hidden{
    display: none;
}

.dot-default {
    margin-right: 7px;
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.dot-selected {
    margin-right: 7px;
    height: 20px;
    width: 20px;
    background-color: #bfe6ff;
    border-radius: 50%;
    display: inline-block;
}

.scroll{
    overflow: auto;
    white-space: nowrap;
}

.question-container{
    padding: 50px 5% 5% 5%;
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
}

.question-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.question-container::-webkit-scrollbar-thumb {
    background-color: #85b4cd;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    background-color: #85b4cd;
}

@media (max-width: 1640px) {
    .home-cards{
        margin: 20px !important;
    }
}

@media (max-width: 1440px) {
    #header{
        width: 100% !important;
    }

    #content-questions {
        max-width: 100% !important;
    }
}

@media (max-width: 1280px) {
    .card-number{
        font-size: 20px !important;
    }
    .card-title{
        font-size: 18px !important;
    }
    .card-description{
        font-size: 15px !important;
    }
    .card-container{
        height:100vh !important;
    }
}

@media (max-width: 1024px) {
    .home-cards{
        margin: 10px !important;
        min-height: 400px;
    }
}

@media (max-width: 768px) {
    #wistia_ixntcuyb6i{
        width: 100% !important;
    }
    .quest_title{
        font-size: 17px !important;
    }
    .home-title{
        padding-top: 20px !important;
    }

    .profile-error{
        display: inline-block;
    }

    .appbar{
        display: none !important;
    }

    #steps-layout-container{
        padding-top: 30px !important;
    }

    .card-container{
        width: 100% !important;
        display: flex !important;
        flex-wrap: wrap;
        flex-direction: row;
        height: auto;
        margin-bottom: 40px;
    }
    .forms-button-container{
        margin-bottom: 40px !important;
    }
    .steps_layout{
        padding-bottom: 30px;
    }

    .home-cards{
        flex-grow: 2 !important;
        width: 200px !important;
    }

    .icon-header{
        height: 60px !important;
    }

    .steps-header{
        font-size: 30px !important;
    }

    .steps-subheader{
        font-size: 23px !important;
    }

    #SOIDIV_PCSRCellularHealthAnalysis{
        overflow: auto;
        white-space: nowrap;
    }

    .question-container{
        padding: 10px !important;
        height: auto !important;
    }

    .reusable-text-questionare-label{
        font-size: 15px !important;
    }

    .reusable-text-questionare-textfield{
        padding-top: 20px !important;
    }

    .reusable-text-questionare-multi-textfield{
        padding-top: 20px !important;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        font-size: 15px !important;
        height: 10px !important;
    }

    #header{
        width: 87% !important;
    }

    #content-questions {
        max-width: 100% !important;
    }
    .hide-card {
        display: none !important;
    }
    .hide-text {
        display: none !important;
    }
}

@media (max-width: 568px){
    #header{
        width: 100% !important;
    }

    #wistia_ixntcuyb6i{
        width: 100% !important;
        height: 240px !important;
    }

    #steps-layout-container{
        padding: 80px 10px 10px 10px !important;
    }
    .card-container{
        width: 100% !important;
        height: auto !important;
        margin-bottom: 40px;
    }
    .appbar{
        display: block !important;
    }
    .forms-button-container{
        margin-bottom: 40px !important;
    }

    .css-b6mtyt-MuiContainer-root {
        max-width: 90% !important;
    }

    .icon-header{
        height: 60px !important;
    }

    .steps-header{
        font-size: 30px !important;
    }

    .steps-subheader{
        font-size: 23px !important;
    }

    .reusable-text-questionare-label{
        font-size: 14px !important;
        padding-top: 10px;
        font-weight: bold !important;
        padding-bottom: 10px;
    }

    .reusable-text-questionare-textfield{
        padding-top: 10px !important;
    }

    .reusable-text-questionare-multi-textfield{
        padding-top: 10px !important;
    }
}
